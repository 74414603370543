import { useEffect, useState } from 'react'
import { isBrowser } from '../utils/generic'
import storageService from '../services/storageService'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

export const REGISTRATION_STEP_IDS = {
  OLD_EMAIL_AND_PASS: 'OLD_EMAIL_AND_PASS',
  OLD_CONFIRM_NAME: 'OLD_CONFIRM_NAME',
  OLD_ADDRESS: 'OLD_ADDRESS',

  EMAIL_AND_PASS: 'EMAIL_AND_PASS',
  SELFIE_AND_ID: 'SELFIE_AND_ID',
  ADDRESS: 'ADDRESS',
  CONFIRM_PHONE: 'CONFIRM_PHONE',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
}

export const REGISTRATION_STEPS = [
  // TODO: When we use the new flow full time, remove these "old" 3 steps
  {
    isOldFlow: true,
    id: REGISTRATION_STEP_IDS.OLD_EMAIL_AND_PASS,
    progress: 1,
    hideBackButton: true,
    showUspsBarDesktop: true,
    showUspsBarMobile: true,
    showBannerOnMobile: true,
  },
  {
    isOldFlow: true,
    id: REGISTRATION_STEP_IDS.OLD_CONFIRM_NAME,
    progress: 2,
  },
  {
    isOldFlow: true,
    id: REGISTRATION_STEP_IDS.OLD_ADDRESS,
    progress: 3,
  },

  {
    id: REGISTRATION_STEP_IDS.EMAIL_AND_PASS,
    progress: 1,
    hideBackButton: true,
    showUspsBarDesktop: true,
    showUspsBarMobile: true,
    showBannerOnMobile: true,
  },
  {
    id: REGISTRATION_STEP_IDS.SELFIE_AND_ID,
    progress: 1,
    showUspsBarDesktop: true,
  },
  {
    id: REGISTRATION_STEP_IDS.ADDRESS,
    progress: 2,
    showUspsBarDesktop: true,
  },
  {
    id: REGISTRATION_STEP_IDS.CONFIRM_PHONE,
    progress: 3,
    showUspsBarDesktop: true,
  },
  {
    id: REGISTRATION_STEP_IDS.CONFIRM_EMAIL,
    progress: 3,
    showUspsBarDesktop: true,
  },
]

export const useRegistrationSteps = () => {
  const useNewFlow = useFeatureIsOn('registration_new_flow')

  const getFirstIndex = () => {
    if (!useNewFlow) return 0
    return REGISTRATION_STEPS.findIndex((step) => !step.isOldFlow) ?? 0
  }

  const getLastIndex = () => {
    if (useNewFlow) return REGISTRATION_STEPS.length - 1
    return (
      REGISTRATION_STEPS.findIndex((step) => !step.isOldFlow) - 1 ??
      REGISTRATION_STEPS.length - 1
    )
  }

  const [formStep, setFormStep] = useState(getFirstIndex())
  const [isLastStep, setIsLastStep] = useState(false)

  const getPrevStepNum = (current) => {
    const newStep = current - 1
    const firstIndex = getFirstIndex()
    if (newStep < firstIndex) return firstIndex

    return newStep
  }

  const getNextStepNum = (current) => {
    const newStep = current + 1
    const lastIndex = getLastIndex()
    if (newStep >= lastIndex) return lastIndex

    return newStep
  }

  const goBack = () => setFormStep(getPrevStepNum)
  const goToNextStep = () => setFormStep(getNextStepNum)

  useEffect(() => {
    const lastIndex = getLastIndex()
    if (isLastStep && formStep !== lastIndex) {
      setIsLastStep(false)
    } else if (!isLastStep && formStep === lastIndex) {
      setIsLastStep(true)
    }
  }, [isLastStep, formStep])

  const scrollToTop = () => {
    const registerModal = document.getElementById('register-modal')
    let modalElem = registerModal?.parentElement?.parentElement
    if (!modalElem) modalElem = window

    const formElem = document.getElementById('register-form')
    formElem.scrollTo({ top: 0 })
    modalElem.scrollTo({ top: 0 })
  }

  useEffect(() => {
    if (!isBrowser()) return
    storageService.setValue('lastFormStep', formStep)
    scrollToTop()
  }, [formStep])

  return { formStep, isLastStep, goToNextStep, goBack }
}
