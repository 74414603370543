import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { CafIFrame } from '../../../../molecules/cafIFrame'
import { StyledCheckedIcon, StyledGenericContentWrapper } from '../../styles'
import { Text, Title } from '../../../../atoms/text'
import { Image } from '../../../../atoms/image'
import icon_selfie_and_id from '../../../../../images/icon_selfie_and_id_gray.svg'
import icon_selfie_and_id_failed from '../../../../../images/icon_selfie_and_id_red.svg'
import { Button } from '../../../../atoms/button'
import {
  doCafIframeCheck,
  getRegistrationCpfCheckStatus,
} from '../../../../../adapters/auth'
import { FIVE_SECONDS } from '../../../../../utils/constants'
import { toast } from '../../../../atoms'
import { useTranslation } from '../../../../../context/translationProvider'
import { AlreadyDoneContent } from '../../customComponents/alreadyDoneContent'
import { captureException } from '@sentry/gatsby'

const SelfieAndIdStep = (props) => {
  const {
    cafData,
    onCompleteStep,
    stepIsDone,
    goBack,
    formProperties,
    setCafData,
  } = props

  const { translate } = useTranslation()
  const { getValues, setValue } = formProperties

  const [showIframe, setShowIframe] = useState(false)
  const [showError, setShowError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { onboardingUrl, onboardingId, kycDocumentType } = cafData ?? {}

  const intervalId = useRef(null)
  const onboardingIdRef = useRef(onboardingId)
  onboardingIdRef.current = onboardingId

  const getNewCafData = async () => {
    const afId = getValues('afId')
    const nationalityId = getValues('nationality')
    const { ok, data } = await getRegistrationCpfCheckStatus(
      afId,
      nationalityId
    )

    if (!data?.afId || !ok) {
      toast.error(translate('common.unexpectedError'))
      captureException('CPF Check returned no data for afId: ' + afId)
      setCafData(null)
      setValue('afId', null)
      setValue('onboardingId', null)
      goBack()
      return
    }

    setCafData(data)
    setValue('afId', data.afId)
    setValue('onboardingId', data.onboardingId)
    onboardingIdRef.current = data.onboardingId
  }

  const onRetry = async () => {
    setIsLoading(true)
    await getNewCafData()
    onStart()
    setShowError(false)
    setIsLoading(false)
  }

  const onStart = () => {
    setShowIframe(true)
    if (intervalId.current) clearInterval(intervalId.current)
    intervalId.current = setInterval(poolIframeCheck, FIVE_SECONDS)
  }

  const poolIframeCheck = async () => {
    const { ok, data } = await doCafIframeCheck(onboardingIdRef.current)
    if (ok && data?.status === 'APPROVED') {
      onCompleteStep()
    }
    if (data?.status === 'REJECTED') {
      toast.error(translate('registration.iframe.rejected'))
      setShowError(true)
      setIsLoading(false)
      setShowIframe(false)
      if (intervalId.current) clearInterval(intervalId.current)
    }
  }

  useEffect(() => {
    return () => {
      if (intervalId.current) clearInterval(intervalId.current)
    }
  }, [])

  const translationKey =
    kycDocumentType == 'identificationAndSelfie' ? 'selfieAndId' : 'passport'

  if (showError) {
    return (
      <StyledGenericContentWrapper>
        <Title level={2}>
          {translate(`registration.${translationKey}.failTitle`)}
        </Title>
        <StyledCheckedIcon url={icon_selfie_and_id_failed} isFail />
        <Text textAlign="center">
          {translate(`registration.${translationKey}.failDescription`)}
        </Text>

        <Button
          id="try-again"
          expand
          loading={isLoading}
          onClick={onRetry}
          type="button"
        >
          {translate('common.tryAgain')}
        </Button>
        <Button
          id="back"
          expand
          dark
          bordered
          disabled={isLoading}
          onClick={goBack}
          type="button"
        >
          {translate('common.back')}
        </Button>
      </StyledGenericContentWrapper>
    )
  }

  if (showIframe) {
    return (
      <StyledGenericContentWrapper className="iframe-wrapper">
        <CafIFrame shouldUpdateUser={false} cafAltIframeUrl={onboardingUrl} />
      </StyledGenericContentWrapper>
    )
  }

  if (stepIsDone) {
    return (
      <AlreadyDoneContent
        icon={icon_selfie_and_id}
        title={translate('register.alreadyDone.iframe.title')}
        description={translate('register.alreadyDone.iframe.description')}
        onNext={() => onCompleteStep()}
      />
    )
  }

  return (
    <StyledGenericContentWrapper>
      <Title level={2}>
        {translate(`registration.${translationKey}.title`)}
      </Title>

      <Image url={icon_selfie_and_id} />
      <Text textAlign="center">
        <span
          dangerouslySetInnerHTML={{
            __html: translate(
              `registration.${translationKey}.normalDescription`
            ),
          }}
        />
      </Text>
      <Button onClick={onStart} expand type="button">
        {translate('common.start')}
      </Button>
    </StyledGenericContentWrapper>
  )
}

SelfieAndIdStep.propTypes = {
  cafData: PropTypes.object,
  onCompleteStep: PropTypes.func,
  stepIsDone: PropTypes.bool,
  goBack: PropTypes.func,
  setCafData: PropTypes.func,
  formProperties: PropTypes.object,
}

export { SelfieAndIdStep }
